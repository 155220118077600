<template>
  <div>
    <v-card width="100%" class="text-center" color="#fff">
      <v-img contain max-height="300px" src="/educaaws.jpeg"></v-img>
      <v-btn
        :to="{ name: 'ExplanacaoForm' }"
        v-if="me.role.name === 'admin'"
        color="primary"
        class="text-center mb-4"
      >
        Cadastrar Explanação
      </v-btn>
    </v-card>
    <v-card width="100%" class="text-start" color="#fff">
      <nav class="navbar custom-navbar">
        <form class="form-inline custom-form " @submit.prevent>
          <input
            class="form-control custom-input"
            type="text"
            v-model="query"
            placeholder="Digite aqui"
            aria-label="Search"
            @keyup.enter="search"
          />
          <button class="btn custom-button" type="button" @click="search">
            Buscar
          </button>
        </form>
      </nav>
    </v-card>
    <v-container fluid class="lighten-3" text-center>
      <v-row> </v-row>
      <v-row>
        <v-col v-for="file in dataTable" :key="file.id" cols="12" md="4">
          <v-card style="height: 100%" :color="'#014486'" dark>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="text-h6 text-left text-justify text-break"
                  v-text="file.name"
                ></v-card-title>

                <!-- <v-card-subtitle
                  class="text-left text-start"
                  v-text="'Autor: Wellington Sampaio'"
                ></v-card-subtitle> -->

                <v-card-actions>
                  <v-btn
                    :href="'https://wsjus.com.br/storage/public/' + file.path"
                    class="ml-2 mt-5"
                    target="_blank"
                    outlined
                    rounded
                    small
                  >
                    Ler Agora
                  </v-btn>
                </v-card-actions>
              </div>

              <v-avatar class="ma-3" size="125" tile>
                <v-img
                  v-if="file.image_url"
                  :src="
                    'https://wsjus.com.br/storage/public/' + file.image_url.path
                  "
                ></v-img>
              </v-avatar>
            </div>
          </v-card>
          <!-- <v-card class="text-center">
            <v-card-title class="text-center">
              <div class="text-center w-full" style="width: 100%;">
                {{ file.name }}
              </div>
            </v-card-title>
            <v-card-actions>
              <v-btn
                :href="'https://wsjus.com.br/storage/public/' + file.path"
                target="_blank"
                block
                class="pa-3"
                color="primary"
              >
                Abrir
              </v-btn>
            </v-card-actions>
          </v-card> -->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ToastService from "@/services/ToastService";
import ComercialService from "@/services/Comercial/ComercialService";
import moment from "moment";
import instanceAxios from "../../plugins/axios";
import { mapGetters } from "vuex";
export default {
  created: async function () {
    await this.indexData();
  },

  components: {},
  computed: {
    countProposta: {
      get() {
        var a = this.dataTable.fechado.length;
        var b = this.dataTable.contato.length;
        var c = this.dataTable.enviado.length;
        return a + b + c;
      },
    },
    ...mapGetters({
      me: "getMe",
    }),
  },
  data: () => ({
    ComercialService: new ComercialService(""),
    loading: false,
    query: "",
    dataTable: [],
    headers: [
      { title: "OPORTUNITY", nick: "Oportunidade" },
      { title: "NAME", nick: "Nome" },
      { title: "POST", nick: "Cargo" },
      { title: "EMAIL", nick: "Email" },
      { title: "PHONE", nick: "Telefone" },
      { title: "RETURN", nick: "Retorno" },
    ],
  }),

  filters: {
    formatDate: function (el) {
      return moment(el).format("DD/MM/YYYY");
    },
  },

  methods: {
    async search(e) {

      if (e.key === "Enter") {
        e.preventDefault();
      }

      if (this.query.length > 1 || this.query.length === 0) {
        this.loading = true;
        try {
          let response;
          if (this.query.length > 1) {
            response = await instanceAxios.get(`search`, {
              params: { query: this.query },
            });
          } else {
            response = await instanceAxios.get(`search`, {
              params: { query: "" },
            });
          }
          this.dataTable = response.data;
        } catch (exception) {
          console.error(exception);
          ToastService(exception, "error");
        } finally {
          this.loading = false;
        }
      }
    },

    async indexData() {
      try {
        this.loading = true;

        const res = await instanceAxios.get("templates");
        this.dataTable = res.data;
      } catch (exception) {
        ToastService(exception, "error");
      } finally {
        this.loading = false;
      }
    },
    countPropostas() {
      var a = this.dataTable.contato.length;
      var b = this.dataTable.enviado.length;
      var c = this.dataTable.fechado.length;
      return a + b + c;
    },

    async deleteItem(item) {
      try {
        await instanceAxios.delete(`commercials/${item.id}`);

        ToastService("Deletado com sucesso", "success");

        this.indexData();
      } catch (error) {
        ToastService("Não foi possível excluir", "error");
      }
    },
  },
};
</script>

<style scoped>
.custom-navbar {
  background-color: #f8f9fa !important;
  /* Custom background color */
  padding: 10px !important;
  /* Custom padding */
  border-radius: 5px !important;
  /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  /* Subtle shadow for depth */
}

.custom-form {
  display: flex !important;
  align-items: center !important;
  justify-content: start !important;
}

.custom-input {
  margin-right: 10px !important;
  /* Space between input and button */
  border: 1px solid #ced4da !important;
  /* Custom border color */
  border-radius: 4px !important;
  /* Rounded corners */
  padding: 5px 10px !important;
  /* Padding inside input */
}

.custom-button {
  color: #fff !important;
  /* White text color */
  background-color: #007bff !important;
  /* Custom background color */
  border: none !important;
  /* Remove default border */
  border-radius: 4px !important;
  /* Rounded corners */
  padding: 5px 15px !important;
  /* Padding inside button */
  cursor: pointer !important;
  /* Pointer cursor on hover */
}

.custom-button:hover {
  background-color: #2d029c !important;
  /* Darker green on hover */
}
</style>
